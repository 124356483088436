// $primary: #a51616;
@import url('https://fonts.googleapis.com/css?family=Aref+Ruqaa');

$primary: #96006b; /* MAIN COLOR */
$secondary: #ea5761; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}
body {
	font-family: 'Aref Ruqaa', serif;

}
nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 100px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 22px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $secondary;
	    	color: lighten($secondary, 50%);
	    }
	}
}
.logo {
	@media (max-width: 767px){
		max-height: 70px
	}
}
.navbar-right {
	margin-top: 0px;
}

.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}
// start sections
section {
	padding: 50px 0;
	position: relative;
}
.section-a{
  padding:80px 0;
  background: url('../img/bg1.jpg') no-repeat center/cover;
	@media(max-width: 767px){
		padding:20px 0;
	}
	#logo {
		position: relative;
		@media(max-width: 767px){
			max-width: 150px;
		}
	}
	h1 {
		font-size: 3em;
		font-weight: bold;
		color: $primary;
		text-transform: capitalize;
		text-shadow: 2px 2px 0px darken($primary, 15%);
		padding: 25px 0;
		@media(max-width: 767px){
			font-size:1.7em;
				text-shadow: 1px 1px 0px darken($primary, 15%);
			padding: 0;
		}
	}
}
.section-b {
	background: #B24592; /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #B24592 , #F15F79); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #B24592 , #F15F79); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	h3 {
		color: lighten($wht, 25%);
		font-size: 1.5em;
		font-weight: 100;
	}
}
.section-d {
	background: #B24592; /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #B24592 , #F15F79); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #B24592 , #F15F79); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	h1 {
		font-size: 2.5em;
		font-weight: 100;
		color: lighten($wht, 25%);
	}
	 ul {
		 padding: 0;
	 }
	ul, p{
		font-size: 1.3em;
		color: lighten($wht, 25%);
		margin: 25px 0;
		li {
			list-style: none;
		}
	}
}
// ends sections

/*cta starts */
.cta{
  padding: 20px 45px;
  background: $primary;
	color: $wht;
	margin: 10px 5px ;
	@media (max-width:767px){
		  padding: 10px 25px;
			margin: 5px ;
	}


	&:hover {
		color: $wht;
		background: lighten($secondary, 5%);
		transition: all 1s ease 0s;
	}
}

/*cta ends */

footer {
	padding: 20px 0px 20px;
	background: $primary;
	color: $footerLinks;;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}
